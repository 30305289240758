export const de= {
    'Homepage': 'Startseite',
    'Main Dashboard': 'Haupt-Dashboard',
    'Inventory Dashboard': 'Inventar-Dashboard',
    'Chat': 'Chat',
    'Shelf Planner': 'Regalplaner',
    'Reports': 'Berichte',
    'Catalog': 'Katalog',
    'Stored Items': 'Eingelagerte Artikel',
    'Replenishments': 'Auffüllungen',
    'Expired': 'Abgelaufen',
    'Transfers': 'Übertragungen',
    'In Progress': 'In Bearbeitung',
    'Cancelled': 'Abgebrochen',
    'Suspended': 'Ausgesetzt',
    'Customers': 'Kunden',
    'Goods In': 'Wareneingang',
    'All': 'Alle',
    'PTL Rack': 'PTL-Regal',
    'Cross-Docking': 'Cross-Docking',
    'Resources': 'Ressourcen',
    'Shelf Maps': 'Regalpläne',
    'Warehouses & Zones': 'Lager & Zonen',
    'Import/Export': 'Import/Export',
    'Units of Measure': 'Maßeinheiten',
    'Smart Tags': 'Intelligente Tags',

    'Login to WMS360': 'Anmelden WMS360',
    'Please enter your credentials to sign in!': 'Bitte geben Sie Ihre Anmeldedaten ein, um sich anzumelden!',
    'Email': 'E-Mail',
    'Password': 'Passwort',
    'Remember Me': 'Angemeldet bleiben',
    'Forgot Password?': 'Passwort vergessen?',
    'Signing in...': 'Anmeldung läuft...',
    'Sign In': 'Anmelden',
    'Incorrect email or password': 'Falsche E-Mail oder falsches Passwort',

    'Forgot Password': 'Passwort vergessen',
    'Check your email': 'Überprüfen Sie Ihre E-Mails',
    'We have sent a password recovery instruction to your email': 'Wir haben Anweisungen zur Passwortwiederherstellung an Ihre E-Mail gesendet',
    'Please enter your email address to receive a verification code': 'Bitte geben Sie Ihre E-Mail-Adresse ein, um einen Bestätigungscode zu erhalten',
    'Resend Email': 'E-Mail erneut senden',
    'Send Email': 'E-Mail senden',
    'Back to': 'Zurück zu',
    'Sign in': 'Anmelden',
    'Reset done': 'Zurücksetzen abgeschlossen',
    'Your password has been successfully reset': 'Ihr Passwort wurde erfolgreich zurückgesetzt',
    'Set new password': 'Neues Passwort festlegen',
    'Your new password must different to previous password': 'Ihr neues Passwort muss sich vom vorherigen unterscheiden',
    'Confirm Password': 'Passwort bestätigen',

    'Submitting ...': 'Übermitteln ...',
    'Submit': 'Absenden',
    'Continue': 'Fortfahren',

    'Inventory': 'Inventar',
    "Dashboard": "Dashboard",
    "Filter": "Filter",
    'Pick Confirmed': 'Auswahl bestätigt',
    'Sold': 'Verkauft',
    'Short Items': 'Fehlende Artikel',
    'Fix': 'Beheben',
    "Status": "Status",
    'Paid': 'Bezahlt',
    'Pending': 'Ausstehend',
    'Failed': 'Fehlgeschlagen',
    'Date': 'Datum',
    'Amount': 'Betrag',
    'Snapshot Variance Reports': 'Berichte zur Schnappschuss-Abweichung',
    'Unique Items': 'Einzigartige Artikel',
    'Total Item Count': 'Gesamtanzahl der Artikel',
    'Stock Count': 'Bestandszählung',
    'Inbound Items': 'Eingehende Artikel',

    'Available': 'Verfügbar',
    'Low Stock': 'Niedriger Bestand',
    'Out of Stock': 'Nicht auf Lager',
    'Total Stock': 'Gesamtbestand',

    'Value Distribution': 'Wertverteilung',
    'Vertical': 'Vertikal',
    'Racking': 'Regalierung',
    'Quarantine': 'Quarantäne',
    'Inspection': 'Inspektion',

    'Variance Value': 'Abweichungswert',
    'Warehouse Capacity': 'Lagerkapazität',

    'Activities': 'Aktivitäten',
    'View All': 'Alle anzeigen',
    'No Activities': 'Keine Aktivitäten',

    'Batch Cycles': 'Chargenzyklen',
    'Performance': 'Leistung',
    'Staff': 'Mitarbeiter',

    'Pending Jobs': 'Ausstehende Aufgaben',
    'Completed Jobs': 'Abgeschlossene Aufgaben',
    'Put away': 'Wegräumen',
    'Picks Completed': 'Auswahlen abgeschlossen',
    'Active Staff': 'Aktive Mitarbeiter',
    'Work Load': 'Arbeitsbelastung',

    'Fulfilment Time (avg)': 'Durchführungszeit (Ø)',
    'Order': 'Bestellung',
    'Total': 'Gesamt',
    'Latest Orders': 'Neueste Bestellungen',
    'View Orders': 'Bestellungen anzeigen',

    'Categories': 'Kategorien',

    'Orders Dashboard': 'Bestellübersicht',
    'View your current sales & summary': 'Zeigen Sie Ihre aktuellen Verkäufe und die Zusammenfassung an',
    'Sales Report': 'Verkaufsbericht',
    'Export Report': 'Bericht exportieren',

    'Revenue': 'Umsatz',
    'Orders': 'Bestellungen',
    'Purchases': 'Einkäufe',

    'Processing': 'In Bearbeitung',
    'Return': 'Rückgabe',
    'Units': 'Einheiten',
    'Scan Confirmed': 'Scan bestätigt',

    'Top Selling': 'Meistverkauft',
    'View Products': 'Produkte anzeigen',
    'Article': 'Artikel',
    'Title': 'Titel',
    'Quantity': 'Menge',
    'Expired Date': 'Ablaufdatum',

    'Product': 'Produkt',
    'Products': 'Produkte',
    'Warehouse': 'Lager',
    "Zone": "Zone",
    'Lift': 'Aufzug',
    'Lifts': 'Aufzüge',
    'Lift Type': 'Aufzugstyp',
    'Lift Types': 'Aufzugstypen',
    'Lift Number': 'Aufzugsnummer',
    'Lift Name': 'Aufzugsname',
    'Shelf': 'Regal',
    'Shelves': 'Regale',
    'User': 'Benutzer',
    'Users': 'Benutzer',
    'User name': 'Benutzername',
    'Username': 'Benutzername',
    'Access point': 'Zugangspunkt',
    "Barcode": "Barcode",
    'Width': 'Breite',
    'Height': 'Höhe',
    'Depth': 'Tiefe',
    'Depths': 'Tiefen',
    'Compartment': 'Fach',
    'Compartments': 'Fächer',
    'Type': 'Typ',
    "S/C/D": "S/C/D",
    'Created': 'Erstellt',
    'Color': 'Farbe',
    'Count': 'Zählen',
    'Counts': 'Zählungen',
    "Max": "Max",
    'Put': 'Ablegen',
    'Pick': 'Entnehmen',
    'Location': 'Standort',
    'Locations': 'Standorte',
    'Description': 'Beschreibung',
    'Pick Sheet': 'Auswahlliste',
    'Movements': 'Bewegungen',
    'Movement': 'Bewegung',
    'Job Name': 'Aufgabenname',
    'Required Quantity': 'Erforderliche Menge',
    'Actual Quantity': 'Tatsächliche Menge',
    'Other': 'Andere',
    'Bin': 'Behälter',
    'Bins': 'Behälter',
    'Setting': 'Einstellung',
    'Settings': 'Einstellungen',
    'Max Qty.': 'Max. Menge',

    'Filter by dates range': 'Nach Datumsbereich filtern',
    'Min Qty.': 'Min. Menge',
    'Current Stock': 'Aktueller Bestand',
    'Transfer': 'Übertragen',
    'Add Product': 'Produkt hinzufügen',

    'No product found!': 'Kein Produkt gefunden!',
    'Add': 'Hinzufügen',
    'Cancel': 'Abbrechen',
    'Save': 'Speichern',
    'Edit': 'Bearbeiten',
    'Delete': 'Löschen',
    'Discard': 'Verwerfen',

    'Track #': 'Sendungsnummer',
    'Order Date': 'Bestelldatum',
    'Shipping Method': 'Versandart',
    'Supplier': 'Lieferant',

    'Related Products': 'Zugehörige Produkte',
    'Consignment': 'Sendung',
    'Consigned': 'Konsigniert',
    'Owner': 'Eigentümer',
    'Search Owner': 'Eigentümer suchen',

    'Scan to pick': 'Zum Entnehmen scannen',
    'Active': 'Aktiv',
    'Block': 'Blockieren',
    'Pick Method': 'Entnahmemethode',
    "FIFO": "FIFO",
    'Optimised': 'Optimiert',
    'Security': 'Sicherheit',
    'Stock Take': 'Inventur',
    'Stock Valuation': 'Bestandsbewertung',

    'Unit Of Measure': 'Maßeinheit',
    'Print Label on Pick': 'Etikett beim Entnehmen drucken',
    'Is Serialised': 'Ist serialisiert',

    'Receiving': 'Wareneingang',

    'This field is required': 'Dieses Feld ist erforderlich',
    'Enter correct url!': 'Bitte eine korrekte URL eingeben!',
    'Something wrong! Can not upload file': 'Etwas ist schiefgelaufen! Datei-Upload nicht möglich',
    'Max quantity of the bins are required': 'Maximale Behältermenge ist erforderlich',
    'Product successfully added': 'Produkt erfolgreich hinzugefügt',
    'article is existed.': 'Artikel ist bereits vorhanden.',
    'Error !!!': 'Fehler !!!',
    'Min Quantity': 'Mindestmenge',
    'UOM': 'ME (Maßeinheit)',
    "Fifo": "Fifo",
    'Pick multiplier': 'Entnahme-Multiplikator',
    'Put away multiplier': 'Einlagerungs-Multiplikator',
    'Buy Price': 'Einkaufspreis',
    'Sell Price': 'Verkaufspreis',
    'ERP Code': 'ERP-Code',
    'Manufacturer': 'Hersteller',
    'Model': 'Modell',
    'Make': 'Marke',
    'Is serial numbered': 'Ist mit Seriennummer',
    "H1": "H1",
    "H2": "H2",
    "H3": "H3",
    "H4": "H4",
    "H5": "H5",
    "H6": "H6",
    'Auto print label': 'Etikett automatisch drucken',
    'Scan pick': 'Pick-Vorgang scannen',
    'Product Image': 'Produktbild',
    'Please upload a image file!': 'Bitte laden Sie eine Bilddatei hoch!',
    'Upload image cannot more then {{size}}kb !': 'Das Bild darf nicht größer als {{size}}kb sein!',
    'Add or change image for the product': 'Fügen Sie ein Produktbild hinzu oder ändern Sie es',
    'Drop your image here, or': 'Ziehen Sie Ihr Bild hierher oder',
    'browse': 'durchsuchen',
    'Support: image files': 'Unterstützt: Bilddateien',
    'Use Webcam': 'Webcam verwenden',
    'Capture photo': 'Foto aufnehmen',
    'Are you sure you want to delete this product? All record related to this product will be deleted as well. This action cannot be undone.': 'Sind Sie sicher, dass Sie dieses Produkt löschen möchten? Alle zugehörigen Aufzeichnungen werden ebenfalls gelöscht. Diese Aktion kann nicht rückgängig gemacht werden.',
    'Delete product': 'Produkt löschen',

    'Counted': 'Gezählt',
    'Completed': 'Abgeschlossen',

    'Category': 'Kategorie',
    'Created At': 'Erstellt am',
    'Started At': 'Begonnen am',
    'Completed At': 'Abgeschlossen am',
    'Item count': 'Artikelanzahl',
    'Value': 'Wert',
    'Recounted': 'Neu gezählt',
    'Variance': 'Abweichung',
    'Approved': 'Genehmigt',

    'Checked': 'Geprüft',
    'Checked By': 'Geprüft von',
    'Checked Date': 'Prüfdatum',
    'Comment': 'Kommentar',
    'Sending ...': 'Senden ...',
    'Send': 'Senden',
    'Recount All': 'Alle neu zählen',
    'Approval All': 'Alle genehmigen',
    'Matching': 'Übereinstimmend',
    'Recount': 'Neu zählen',

    'Count Name': 'Zählungsname',
    'Cycle Count': 'Zykluszählung',
    'Select the stock take group to count.': 'Wählen Sie die zu zählende Inventurgruppe aus.',
    'In which warehouse': 'In welchem Lager',
    'Should the articles being counted now be made inactive so they they can not be picked or put until after they have been counted ?': 'Sollen die derzeit gezählten Artikel inaktiv gemacht werden, damit sie nicht entnommen oder eingelagert werden können, bis die Zählung abgeschlossen ist?',
    'Cycle A': 'Zyklus A',
    'Cycle B': 'Zyklus B',
    'Cycle C': 'Zyklus C',
    'No Count': 'Keine Zählung',

    'All Locations': 'Alle Standorte',
    'Shelf Size': 'Regalgröße',
    'Lift/Rack Name': 'Aufzugs-/Regalname',

    'Bulk': 'Masse',
    'Cross Docking': 'Cross-Docking',
    'Inspections': 'Inspektionen',
    'Locker': 'Schließfach',
    'Lockers': 'Schließfächer',
    'Are you sure you want to delete this lift? All record related to this lift will be deleted as well. This action cannot be undone.': 'Sind Sie sicher, dass Sie diesen Aufzug löschen möchten? Alle zugehörigen Daten werden ebenfalls gelöscht. Diese Aktion kann nicht rückgängig gemacht werden.',
    'Delete lift': 'Aufzug löschen',
    'Add Location': 'Standort hinzufügen',
    'Location successfully added': 'Standort erfolgreich hinzugefügt',
    'The location is existed': 'Der Standort ist bereits vorhanden',
    'Rack Number': 'Regalnummer',
    'Rack Name': 'Regalname',
    'Rack Description': 'Regalbeschreibung',
    'Shelves (S)': 'Regale (S)',
    'Compartments (C)': 'Fächer (C)',
    '\'Depths (D)': 'Tiefen (D)',
    'Shelf Width (mm)': 'Regalbreite (mm)',
    'Shelf Depth (mm)': 'Regaltiefe (mm)',
    'No lift found!': 'Kein Aufzug gefunden!',
    'Required': 'Erforderlich',

    'Speed': 'Geschwindigkeit',
    'Security Groups': 'Sicherheitsgruppen',
    'Notes': 'Notizen',

    'Assign a Shelf map': 'Ein Regalplan zuweisen',
    'I understand': 'Ich verstehe',
    'Select the shelf map to assign.': 'Wählen Sie den zuzuweisenden Regalplan aus.',
    'Actions': 'Aktionen',
    'Shelf Map': 'Regalplan',

    'Add Vertical': 'Vertikalen hinzufügen',
    'Edit Vertical': 'Vertikalen bearbeiten',
    'Please Select': 'Bitte auswählen',
    'Lift Group': 'Aufzugsgruppe',
    'Lock Timer': 'Sperrzeit',
    'Number Of Shelves': 'Anzahl Regale',
    'Shelf Width': 'Regalbreite',
    'Shelf Depth': 'Regaltiefe',
    'Serial Number': 'Seriennummer',
    'License Key': 'Lizenzschlüssel',
    'Login Required': 'Anmeldung erforderlich',
    'Manage Articles': 'Artikel verwalten',
    'Auto Generated Article Codes': 'Automatisch generierte Artikelcodes',
    'Use Security': 'Sicherheit verwenden',
    'Vertical successfully added. Restart the mpx VLM Services to activate the new lift': 'Vertikale erfolgreich hinzugefügt. Starten Sie die MPX-VLM-Dienste neu, um den neuen Aufzug zu aktivieren',
    'Generic Error': 'Allgemeiner Fehler',
    'Access Point': 'Zugangspunkt',
    'Language': 'Sprache',
    'Controller PC IP address': 'Controller-PC-IP-Adresse',
    'Label Printer': 'Etikettendrucker',
    'Printer': 'Drucker',
    'Article Label Format': 'Etikettenformat für Artikel',
    'Question 1': 'Frage 1',
    'Question 2': 'Frage 2',
    'Allow Picking': 'Entnahme erlauben',
    'Allow Putting': 'Einlagerung erlauben',
    'Allow Searching': 'Suche erlauben',
    'Allow Get Shelf': 'Regalentnahme erlauben',
    'Reverse Shelf Map': 'Regalplan umkehren',
    'Store After Pick': 'Nach Entnahme einlagern',
    'Store After Job': 'Nach Auftrag einlagern',
    'Manage Lift': 'Aufzug verwalten',
    'Manage Racking': 'Regalierung verwalten',
    'Manage Lockers': 'Schließfächer verwalten',
    'Enable Camera': 'Kamera aktivieren',
    'Left Camera': 'Linke Kamera',
    'Right Camera': 'Rechte Kamera',
    'Images storage': 'Bildspeicher',
    'Select images storage': 'Bildspeicher auswählen',
    'Local Storage': 'Lokaler Speicher',
    "AWS S3": "AWS S3",
    'Local Path': 'Lokaler Pfad',
    'AWS Bucket Name': 'AWS-Bucket-Name',
    'AWS Dir Name': 'AWS-Verzeichnisname',
    'AWS Region': 'AWS-Region',
    'AWS Access Key Id': 'AWS Access Key ID',
    'AWS Secret Access Key': 'AWS Secret Access Key',
    // TODO : orders, pages, resources, shared, components (table, pagination, ...)

    'Put Away': 'Einlagern',
    'Put Away Value': 'Einlagerungswert',
    'Returns': 'Rücksendungen',
    'units': 'Einheiten',
    'days': 'Tage',
    'Oldest Pending Job': 'Ältester ausstehender Auftrag',
    'Average Orders Per Months': 'Durchschnittliche Bestellungen pro Monat',
    'Average Pending Time': 'Durchschnittliche Wartezeit',
    'Picks': 'Entnahmen',
    'Latest Order': 'Neueste Bestellung',
    'Oldest Pending': 'Älteste ausstehende',
    'Created Date': 'Erstellungsdatum',
    'Customer': 'Kunde',

    'Any question?': 'Irgendwelche Fragen?',
    'Message ...': 'Nachricht ...',
    'Sorry I don\'t understand your question': 'Entschuldigung, ich verstehe Ihre Frage nicht',
    'Order History': 'Bestellverlauf',
    'Download Template': 'Vorlage herunterladen',
    'Article Information': 'Artikelinformationen',
    'Drop your file here, or': 'Ziehen Sie Ihre Datei hierher oder',
    'Support': 'Unterstützung',
    'Refresh': 'Aktualisieren',
    'Export': 'Export',
    'Search': 'Suchen',
    'Total {{number}} Items': 'Gesamtzahl {{number}} Artikel',
    'page': 'blatt',

    'Copyright': 'Urheberrecht',
    'Term & Conditions': 'Allgemeine Geschäftsbedingungen',
    'Privacy & Policy': 'Datenschutz und Richtlinien',
    'Profile': 'Profil',
    'Sign Out': 'Abmelden'
}
