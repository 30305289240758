import React from 'react'
import { useTranslation } from 'react-i18next'

const Total = (props) => {
  const { total } = props
  const { t } = useTranslation()
  return (
    <div className="pagination-total">
      {t('Total {{number}} Items', { number: total })}
    </div>
  )
}

export default Total
